.text-link-with-icon {
  display: inline-block;
  position: relative;

  .text {
    display: inline-block;
    padding-left: 40px;
    margin-bottom: 4px;
  }

  .icon {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &:hover .text {
    text-decoration: underline;
  }
}
label {
  display: block;
  width: 100%;
  margin-bottom: 3px;
}

input {
  width: 100%;
  border: 1px solid $color-primary-action;
  padding: 13.5px 15px;
  margin-bottom: 10px;
}

textarea {
  @extend input;
  height: 157px;
  resize: vertical;
}

.form-output {
	transition: opacity .3s;
	visibility: hidden;
	opacity: 0;

  &:not(:empty) {
		visibility: visible;
		opacity: 1;
    border: 1px solid transparent;
    padding: 10px;
    text-align: left;
    color: black;
  }

  &.error {
    background: rgba(#e74c3c, .4);
    border-color: #c0392b;
  }

  &.success {
    border-color: $color-primary-action;
    color: #fff;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $color-primary-action;
			opacity: .75;
			z-index: -1;
		}
  }
}

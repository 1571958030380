.wrapper {
  max-width: 1340px;
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;
}

main {
  padding: 10px 0 30px 0;
}

@media (max-width: 1300px) {
  .wrapper {
    padding: 0 10px;
  }
}
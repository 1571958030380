h1 {
  font-size: 40px;
}

h2 {
  font-size: 22px;
}

strong {
  font-weight: bold;
}
@font-face {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: normal;
	src: local('Segoe UI'), url('/assets/fonts/Segoe UI.woff') format('woff');
}

@font-face {
	font-family: 'Segoe UI';
	font-style: italic;
	font-weight: normal;
	src: url('/assets/fonts/Segoe UI Italic.woff') format('woff');
}

@font-face {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: bold;
	src: url('/assets/fonts/Segoe UI Bold.woff') format('woff');
}

@font-face {
	font-family: 'Segoe UI';
	font-style: italic;
	font-weight: bold;
	src: url('/assets/fonts/Segoe UI Bold Italic.woff') format('woff');
}

.foodcase-block {
  display: flex;

  .foodcase-content {
    flex: 1 1 700px;
  }

  .foodcase-clients {
    flex: 1 1 445px;
    margin-left: 25px;
    text-align: center;
    font-size: 0;

    .foodcase-client {
      display: inline-block;
      margin: 10px 20px 0 20px;
      padding: 5px;
      font-size: 22px;
      text-decoration: none;

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: $mq-lg) {
  .foodcase-block {
    display: block;

    .foodcase-content, .foodcase-clients {
      display: block;
      width: 100%;
    }

    .foodcase-clients {
      margin-top: 15px;
      margin-left: 0;
    }
  }
}
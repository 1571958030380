* {
  box-sizing: border-box;
}

body {
  background: #fff;
  color: $color-default;
  font-size: 16px;
  line-height: (4/3);
  font-family: 'Segoe UI', sans-serif;
  overflow-y: scroll;
}

input, textarea, select, button {
  outline: none;
  font-size: 16px;
  line-height: (4/3);
  font-family: 'Segoe UI', sans-serif;
}

a {
  color: inherit;
}

ol {
  list-style: inside decimal;
}

img {
  max-width: 100%;
}

.d-flex {
  display: flex;
}

.c-primary {
  color: $color-primary;
}

.c-error {
	color: #c0392b;
}

.text-center {
  text-align: center;
}

.position-relative {
	position: relative;
}

.mt-0 {
	margin-top: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.big-link {
  display: inline-block;
  color: $color-primary;
  font-size: 22px;
  text-decoration: underline;
}

.clearfix:after {
	display: table;
	content: '';
	clear: both;
}

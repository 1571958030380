.segment {
  margin: 35px 0;

  .segment-header {
    position: relative;
    display: block;
    font-size: 24px;
    padding: 0 15px 5px 15px;
    margin-bottom: 10px;
    color: $color-primary;
    border-bottom: 2px solid $color-primary;
    @extend .clearfix;

    .segment-header-note {
      float: right;
      font-size: 16px;
      margin-top: 8px;
    }
  }

  .segment-body {
    padding: 0 35px;
  }
}

@media (max-width: $mq-md) {
  .segment {
    .segment-header {
      padding-right: 15px;
    }

    .segment-body {
      padding: 0 15px;
    }
  }
}
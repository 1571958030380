/* You can add global styles to this file, and also import other style files */
@import '~reset-css/sass/reset';
@import 'styles/config/variables';

@import 'styles/globals/fonts';
@import 'styles/globals/globals';
@import 'styles/globals/wrappers';
@import 'styles/globals/helpers';

@import 'styles/elems/typography';
@import 'styles/elems/table';
@import 'styles/elems/forms';
@import 'styles/elems/btns';

@import 'styles/components/global-loader';
@import 'styles/components/segment';
@import 'styles/components/sub-segment';
@import 'styles/components/text-link-with-icon';
@import 'styles/components/foodcase-block';

table {
  font-size: 16px;
  width: 100%;
  text-align: left;

  th, td {
    border: 1px solid $color-table-border;
    padding: 2px 8px 4px 8px;
  }

  th {
    font-weight: bold;
  }
}
:root {
	--color-primary: #0098D4;
	--color-primary-action: #52ADE6;
	--color-header-bg: #E7F4FF;
}

$color-default: #848587 !default;
$color-primary: var(--color-primary) !default;
$color-primary-action: var(--color-primary-action) !default;
$color-table-border: #707070 !default;
$color-header: var(--color-header-bg) !default;

$mq-xl: 1400px;
$mq-lg: 1100px;
$mq-md: 800px;
$mq-sm: 550px
